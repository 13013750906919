import React, {Component} from 'react';
import PropTypes from 'prop-types';
import iconWolf from './icon-wolf.png'
import {withSessionContext} from "../context/sessionContext";
import isEmpty from "lodash/isEmpty"
import capitalize from "lodash/capitalize"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Nav from "react-bootstrap/Nav"
import {Auth} from 'aws-amplify';
import {withAppContext} from "../context/appContext";

class AppHeader extends Component {

    renderLoginBtn = () => {
        const handleOnClick = () => {
            this.props.setIsLoginModalShown(!this.props.isLoginModalShown)
        }

        return (
            <Nav>
                <Nav.Link eventKey={2} onClick={handleOnClick}>
                    Login
                </Nav.Link>
            </Nav>
        )
    }

    renderLogoutBtn = () => {
        const handleOnClick = () => {
            this.props.setIsLoginModalShown(false)
            return Auth.signOut()
        }

        return (
            <NavDropdown title={`Welcome, ${capitalize(this.props.playerName)}`} id="app-header-nav-dropdown">
                <NavDropdown.Item  onClick={handleOnClick}>Logout</NavDropdown.Item>
            </NavDropdown>
        )
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Navbar.Brand href="#home">
                    <img src={iconWolf} width="30" height="30" alt="" loading="lazy"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    {isEmpty(this.props.userData) ? this.renderLoginBtn() : this.renderLogoutBtn()}
                </Navbar.Collapse>
            </Navbar>
            //
            // <nav className="navbar navbar-expand-lg navbar-light bg-light">
            //     <span className="navbar-brand" href="#">
            //         <img src={iconWolf} width="30" height="30" alt="" loading="lazy"/>
            //     </span>
            //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
            //             aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            //         <span className="navbar-toggler-icon" />
            //     </button>
            //     <div className="collapse navbar-collapse" id="navbarText">
            //         <ul className="navbar-nav mr-auto">
            //             <li className="nav-item active">
            //                 <span className="nav-link" href="#">Home <span className="sr-only">(current)</span></span>
            //             </li>
            //         </ul>
            //         {isEmpty(this.props.userData) ? this.renderLoginBtn() : this.renderLogoutBtn()}
            //     </div>
            // </nav>
        );
    }
}

AppHeader.propsType = {
    isLoginModalShown: PropTypes.bool,
    setIsLoginModalShown: PropTypes.func,
    userData: PropTypes.object,
    authState: PropTypes.object,
    playerName: PropTypes.string
}

export default withAppContext(withSessionContext(AppHeader))
