/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTimer = /* GraphQL */ `
  subscription OnCreateTimer {
    onCreateTimer {
      id
      countdownValue
      hasTimerStarted
      updatedAt
      createdAt
    }
  }
`;
export const onUpdateTimer = /* GraphQL */ `
  subscription OnUpdateTimer {
    onUpdateTimer {
      id
      countdownValue
      hasTimerStarted
      updatedAt
      createdAt
    }
  }
`;
export const onDeleteTimer = /* GraphQL */ `
  subscription OnDeleteTimer {
    onDeleteTimer {
      id
      countdownValue
      hasTimerStarted
      updatedAt
      createdAt
    }
  }
`;
export const onCreatePlayer = /* GraphQL */ `
  subscription OnCreatePlayer {
    onCreatePlayer {
      id
      isAlive
      isPlayerTurn
      isSelected
      name
      role
      updatedAt
      createdAt
    }
  }
`;
export const onUpdatePlayer = /* GraphQL */ `
  subscription OnUpdatePlayer {
    onUpdatePlayer {
      id
      isAlive
      isPlayerTurn
      isSelected
      name
      role
      updatedAt
      createdAt
    }
  }
`;
export const onDeletePlayer = /* GraphQL */ `
  subscription OnDeletePlayer {
    onDeletePlayer {
      id
      isAlive
      isPlayerTurn
      isSelected
      name
      role
      updatedAt
      createdAt
    }
  }
`;
