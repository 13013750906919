import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ProgressBar from "react-bootstrap/ProgressBar";
import {updateToastMessageToDb} from "../../dataStore/dataStore";

let interval = null

class TimerProgressBar extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (!this.props.isNarrator) {
        return
    }

    if (!this.props.hasGameStarted) {
      this.resetTimer()
      return
    }

    if (this.props.hasTimerStarted === true && prevProps.hasTimerStarted === false) {
      this.startTimer()
    }

    // this.resetTimer()

  }

  startTimer = () => {
      interval = setInterval(() => {

        if (this.props.countdownValue <= 0 || !this.props.hasTimerStarted) {
          this.resetTimer()
          return
        }
        console.log(this.props.countdownValue)
        this.props.updateTimerState('countdownValue', this.props.countdownValue - 1)

      }, 500)
  }

  stopTimer = () => {
    clearTimeout(interval)
    this.props.updateTimerState("hasTimerStarted", false)
  }

  resetTimer = () => {
    this.stopTimer()
    this.props.updateTimerState("hasTimerStarted", false)
    this.props.updateTimerState("countdownValue", this.props.defaultValueInSeconds)
  }

  getVariant = () => {
    if (this.props.countdownValue < 30) {
      updateToastMessageToDb('Alert', 'You have 15 more seconds', true)
      return "danger"
    }

    return this.props.hasTimerStarted ? "success" : "dark"
  }

  render() {

    return (
      <ProgressBar
        animated={this.props.hasTimerStarted}
        onClick={() => this.stopTimer() }
        className={"player-stats-timer-progress-bar"}
        variant={this.getVariant()}
        now={this.props.countdownValue/this.props.defaultValueInSeconds * 100}
      />
    );
  }
}

TimerProgressBar.propsType = {
  defaultValueInSeconds: PropTypes.number,
  hasTimerStarted: PropTypes.bool,
  countdownValue: PropTypes.number,
  updateTimerState: PropTypes.func,
  hasGameStarted: PropTypes.bool,
  isNarrator: PropTypes.bool
}

export default TimerProgressBar;
