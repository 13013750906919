import React, {useState} from "react";
import {Auth} from 'aws-amplify'
import noop from 'lodash/noop'


export const SessionContext = React.createContext({
    isLoginModalShown: false,
    setIsLoginModalShown: () => {
    }
})

export const getCurrentAuthUser = (cb) => {
    Auth.currentAuthenticatedUser()
        .then((userData) => {
            cb(userData)
        }).catch(noop)
}

export const SessionProvider = props => {
    const [isLoginModalShown, setIsLoginModalShown] = useState(false);
    const [userData, setUserData] = useState({});
    const [authState, setAuthState] = useState({});

    return (
        <SessionContext.Provider value={{
            isLoginModalShown, setIsLoginModalShown,
            userData, setUserData,
            authState, setAuthState
        }}>
            {props.children}
        </SessionContext.Provider>
    );
};

export function withSessionContext(Component) {
    return (props) => (
        <SessionContext.Consumer>
            {(appContext) => (
                <Component
                    {...appContext}
                    {...props}
                />
            )}

        </SessionContext.Consumer>
    )
}
