/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayerOne = /* GraphQL */ `
  query GetPlayerOne {
    getPlayerOne {
      id
      isAlive
      isPlayerTurn
      isSelected
      name
      role
      updatedAt
      createdAt
    }
  }
`;
export const getTimer = /* GraphQL */ `
  query GetTimer($id: ID!) {
    getTimer(id: $id) {
      id
      countdownValue
      hasTimerStarted
      updatedAt
      createdAt
    }
  }
`;
export const listTimers = /* GraphQL */ `
  query ListTimers(
    $filter: ModelTimerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countdownValue
        hasTimerStarted
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      isAlive
      isPlayerTurn
      isSelected
      name
      role
      updatedAt
      createdAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAlive
        isPlayerTurn
        isSelected
        name
        role
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
