import {
  resetVoteToDb,
  updateIsVoteShownToDb,
  updatePastVotesToDb,
  updateTimerToDb,
  updateToastMessageToDb
} from "../../dataStore/dataStore";
import React from "react";
import {withAppContext} from "../../context/appContext";
import {FaRandom, FaRegEnvelope, FaRegEnvelopeOpen} from "react-icons/fa";
import getRandomPlayer from "./getRandomPlayer";
import Tooltip from "../../helper/tooltip/Tooltip";
import {withVotesContext} from "../../context/votesContext";
import {withToastMessageContext} from "../../context/toastMessageContext";

const HeaderRow = ({votes, isVoteShown, pastVotes, isNarrator, hasGameStarted, players}) => {

  const renderIdxCol = () => {
    const handleGetRandomPlayer = () => {
      return getRandomPlayer(players)
        .then(() => updateTimerToDb({
            countdownValue: 180,
            hasTimerStarted: hasGameStarted
          })
        )
    }

    if (isNarrator && hasGameStarted) {
      return (
        <th
          className={"text-center player-stats-random-dice"}
          onClick={handleGetRandomPlayer}
        >
          <Tooltip
            id={"player-stats-header-row-toggle-vote"}
            text={"Pick a random player"}
          >
            <FaRandom/>
          </Tooltip>
        </th>
      )
    }

    return (
      <th className={"text-center"}>#</th>
    )
  }

  const toggleIsVoteShown = () => {

    if (isVoteShown) {

      return resetVoteToDb(votes)
        .then(() => updateIsVoteShownToDb(!isVoteShown))
        .then(() => updateToastMessageToDb(
          "Voting has started",
          "The council bell has rang. Your vote matter, check the box besides the player name to cast your vote.",
          true)
        )
    }

    return handleSavePastVotes().then(() => updateIsVoteShownToDb(!isVoteShown))

  }

  const handleSavePastVotes = () => {

    const getPlayersThatAreAlive = () => {
      return players.reduce((acc, player, idx) => {
        if (player.isAlive) {
          acc.push(idx)
        }
        return acc
      }, [])
    }

    const getPlayerWhoVoted = () => {
      return Object.keys(votes).reduce((acc, playerIdx) => {
        if (votes[playerIdx] >= 0) {
          acc.push(parseInt(playerIdx))
        }
        return acc
      }, [])
    }

    const getPlayersWhoDidNotVote = (arr1, arr2) => {
      console.log(arr1, arr2)
      return arr1.filter(x => !arr2.includes(x))
    }

    const playersWhoDidNotVote = getPlayersWhoDidNotVote(getPlayersThatAreAlive(), getPlayerWhoVoted())
    return updatePastVotesToDb([...pastVotes, {...votes, "noVotes": playersWhoDidNotVote}]).then(
      () => updateToastMessageToDb(
        "Votes have been recorded",
        "Click past votes to view the latest voting results",
        true)
    )
  }

  const renderEnvelopeOpenIcon = () => {
    return (
      <Tooltip
        id={"player-stats-header-row-toggle-vote-envelope-open"}
        text={"Show voting results"}
      >
        <FaRegEnvelopeOpen
          className={'player-stats-header-row-toggle-vote-envelope-open'}
          onClick={toggleIsVoteShown}
        />
      </Tooltip>
    )
  }

  const renderEnvelopeCloseIcon = () => {
    return (
      <Tooltip
        id={"player-stats-header-row-toggle-vote-envelope-close"}
        text={"Start voting"}
      >
        <FaRegEnvelope
          className={'player-stats-header-row-toggle-vote-envelope-close'}
          onClick={toggleIsVoteShown}
        />
      </Tooltip>
    )
  }

  const renderVoteCol = () => {
    if (isNarrator && hasGameStarted) {
      return (
        <th className={"text-center"}>
          <span>
            {isVoteShown ? renderEnvelopeOpenIcon() : renderEnvelopeCloseIcon()}
          </span>
        </th>
      )
    }

    return (
      <th className={"text-center"}>
        {!hasGameStarted && isNarrator ? "" : <FaRegEnvelope/>}
      </th>
    )
  }

  return (
    <thead>
    <tr>
      {renderIdxCol()}
      <th>Player Name</th>
      <th className={"text-center"}>Role</th>
      <th className={"text-center"}>Status</th>
      {renderVoteCol()}
    </tr>
    </thead>
  )
}

export default withAppContext(withToastMessageContext(withVotesContext(HeaderRow)))
