import {updatePlayersToDb} from "../../dataStore/dataStore";

export default (playerName, playerRole, players, callback) => {

  players.push({
    name: playerName,
    role: playerRole,
    isAlive: true,
    isSelected: false,
    isPlayerTurn: false
  })
  return updatePlayersToDb(players)
    .then(() => callback())
}

export const isPlayerNameValid = (players, playerName) => {
  if (playerName === undefined) {
    return false
  }

  const filteredPlayer = players.filter((player) => {
    return player.name === playerName;
  })

  return filteredPlayer.length <= 0;
}
