import firebase from "firebase";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import get from "lodash/get"

const config = {
  apiKey: "AIzaSyDUGCmrH2JVzKQV-ZWPVsR2Qg9DVvt-Tps",
  authDomain: "werewolves-6c2aa.firebaseapp.com",
  databaseURL: "https://werewolves-6c2aa.firebaseio.com",
};
firebase.initializeApp(config);
export const db = firebase.database();

// App sync
export const getListOfPlayersFromDb = () => {
  return API.graphql(graphqlOperation(queries.listPlayers))
}

export const deletePlayerInDb = (playerId) => {
  return API.graphql(graphqlOperation(mutations.deletePlayer, {input: {id: playerId}}))
}

export const deleteAllPlayersInDb = () => {
  return getListOfPlayersFromDb()
      .then((players) => get(players, "data.listPlayers.items").map((item) => get(item, 'id')))
      .then((playerIds) => playerIds.map((playerId) => deletePlayerInDb(playerId)))
      .then((deletePlayers) => Promise.all(deletePlayers))
}

export const createPlayerInDb = (player) => {
  return API.graphql(graphqlOperation(mutations.createPlayer, {
    input: {
      ...player
    }
  })).catch((err) => {
    console.error(err)
  })
}

// Firebase
export const updatePlayersToDb = (players) => {

  // return deleteAllPlayersInDb()
  //     .then(() => players.map((player) => createPlayerInDb(player)))
  //     .then((createPlayers) => Promise.all(createPlayers))

  return db.ref("players").set(players)
}

export const updateGameStatusToDb = (gameState) => {
  return db.ref("gameStatus").set({
    ...gameState
  })
}

export const updateIsVoteShownToDb = (showVote) => {
  return db.ref("gameStatus").child("isVoteShown").set(showVote)
}

export const updateVoteToDb = (votes) => {
  return db.ref("votes").set({
    ...votes
  })
}

export const updateAdminUuidToDb = (uuid) => {
  return db.ref("admin").child("uuid").set(uuid)
}

export const updateTimerToDb = (timer) => {
  return db.ref("timer").set({
    ...timer
  })
  // return API.graphql(graphqlOperation(mutations.updateTimer, {
  //   input: {
  //     id: "a644188e-5b2f-4715-a2f7-63848de6b431",
  //     ...timer
  //   }
  // }))
}

export const resetVoteToDb = (votes) => {
  let res = Object.keys(votes).reduce((acc, key) => {
    acc[key] = -1
    return acc
  }, {})

  return db.ref("votes").set({
    ...res
  })
}

export const updatePastVotesToDb = (pastVotes) => {
  return db.ref("pastVotes").set(pastVotes)
}

export const updateToastMessageToDb = (headerMessage, message, isToastMessageShown) => {
  return db.ref("toast").set({headerMessage, message, isToastMessageShown})
}
