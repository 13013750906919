import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AddPlayerModal from "./addPlayerModal/AddPlayerModal";
import AdminButtons from "../adminButtons/AdminButtons";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import get from 'lodash/get'
import set from 'lodash/set'
import findIndex from 'lodash/findIndex'
import isEmpty from "lodash/isEmpty";
import PastVotes from "./pastVotes/PastVotes";
import PlayerName from "./playerName/PlayerName";
import PlayerStats from "./playerStats/PlayerStats";
import Row from "react-bootstrap/Row";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import {db, updatePlayersToDb, getListOfPlayersFromDb, deletePlayersToDb} from "../dataStore/dataStore";
import {Hub} from "aws-amplify";
import {isNarrator, withAppContext} from "../context/appContext"
import {getCurrentAuthUser, withSessionContext} from "../context/sessionContext";
import {withToastMessageContext} from "../context/toastMessageContext";
import {withVotesContext} from "../context/votesContext";

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';

class AppContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currTab: "player-stats",
            isAddPlayerModalShown: false,
        }
    }

    componentDidMount() {
        this.listenToDbEvents()
        this.listenToAuthEvents()
        // this.listenToGraphQl()

        if (isEmpty(this.props.userData)) {
            getCurrentAuthUser((userData) => {
                this.props.setUserData(userData)
                this.updatePlayerName()
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userData !== prevProps.userData) {
            this.updatePlayerName()
        }
    }

    listenToGraphQl = () => {
        API.graphql(graphqlOperation(queries.listPlayers)).then((res) => {
            console.log(get(res, "data.listPlayers"))
            this.props.setPlayers(get(res, "data.listPlayers.items"));
        })

        API.graphql(graphqlOperation(subscriptions.onCreatePlayer)).subscribe({
            next: () => {
                API.graphql(graphqlOperation(queries.listPlayers)).then((res) => {
                    this.props.setPlayers(get(res, "data.listPlayers.items"));
                })
            },
            error: (err) => {console.log(err)}
        })
    }

    updatePlayerName = () => {
        this.props.setPlayerName(get(this.props.userData, 'attributes.name', ''))
        if (isNarrator(this.props.userData)) {this.props.setIsNarrator(true)}
    }

    togglePlayerSelected = (playerName, val) => {
        const playerId = findIndex(this.props.players, (player) => player.name === playerName)

        if (playerId > -1) {
            set(this.props.players, `${playerId}.isSelected`, val)
            updatePlayersToDb(this.props.players)
                .catch((err) => {console.error(err)})
        }
    }

    listenToAuthEvents = () => {
        Hub.listen('auth', (data) => {
            const userData = get(data, "payload.data");
            const event = get(data, 'payload.event')
            const eventName = {
                signIn: "signIn",
                signOut: "signOut"
            }

            if (event === eventName.signOut) {
                this.togglePlayerSelected(this.props.playerName, false)
                this.props.setUserData({})
                this.props.setAuthState(data.payload.event);
            }

            if (event === eventName.signIn) {
                this.props.setAuthState(data.payload.event);
                this.props.setUserData(userData)
                this.props.setIsLoginModalShown(false)
                this.togglePlayerSelected(get(userData, 'attributes.name'), true)
            }

        })
    }

    listenToDbEvents = () => {
        db.ref("players").on("value", snapshots => {
            const players = []
            snapshots.forEach((snapshot) => {
                players.push(snapshot.val())
            })
            this.props.setPlayers(players);
        })

        db.ref("gameStatus").on("value", snapshots => {
            const {hasGameStarted, isVoteShown} = snapshots.val()
            this.props.setHasGameStarted(hasGameStarted);
            this.props.setIsVoteShown(isVoteShown)
        })

        db.ref("votes").on("value", snapshots => {
            const snapshot = snapshots.val()
            let votes = this.state.votes
            Object.keys(snapshot).forEach((key) => {
                votes = {...votes, [key]: snapshot[key]}
            })

            this.props.setVotes(votes);
        })

        db.ref("pastVotes").on("value", snapshots => {
            const pastVotes = []
            snapshots.forEach((snapshot) => {
                pastVotes.push(snapshot.val())
            })

            this.props.setPastVotes(pastVotes);
        })
        db.ref("toast").on("value", snapshots => {
            const {message, isToastMessageShown, headerMessage} = snapshots.val()
            this.props.setHeaderMessage(headerMessage)
            this.props.setMessage(message);
            this.props.setIsToastMessageShown(isToastMessageShown);
        })
    }

    handleNavBarChange = (value) => {
        this.setState({currTab: value});
    }

    renderPlayerStats = () => {
        return (
            <div>
                <Row>
                    <Col>
                        <PlayerStats votes={this.props.votes}/>
                    </Col>
                </Row>
                <AdminButtons
                    toggleAddPlayerModal={() => this.setState({isAddPlayerModalShown: !this.state.isAddPlayerModalShown})}
                />
            </div>
        )
    }

    renderPastVotes = () => {
        return (
            <PastVotes/>
        )
    }

    render() {

        return (
            <Container className={"app-container"}>
                <Row>
                    <Col>
                        <PlayerName/>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-right app-container-nav-bar"}>
                        <ToggleButtonGroup
                            name="app-container-nav-bar"
                            value={this.state.currTab}
                            onChange={this.handleNavBarChange}
                            size={'sm'}
                        >
                            <ToggleButton variant="light" value={"player-stats"}>Players</ToggleButton>
                            <ToggleButton variant="light" value={"past-votes"}>Past votes</ToggleButton>
                            <ToggleButton variant="light" value={"help"}>Help</ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                {this.state.currTab === "player-stats" ? this.renderPlayerStats() : null}
                {this.state.currTab === 'past-votes' ? this.renderPastVotes() : null}


                <AddPlayerModal
                    isAddPlayerModalShown={this.state.isAddPlayerModalShown}
                    toggleAddPlayerModal={() => this.setState({isAddPlayerModalShown: !this.state.isAddPlayerModalShown})}
                />
            </Container>
        );
    }
}

AppContainer.propsType = {
    getRandomPlayer: PropTypes.func,
    handleAliveState: PropTypes.func,
    players:PropTypes.array,
    randomPlayer: PropTypes.object,
    renderPlayerName: PropTypes.func,
    setAuthState: PropTypes.func,
    setIsLoginModalShown: PropTypes.func,
    setIsNarrator: PropTypes.func,
    setUserData: PropTypes.func,
    userData: PropTypes.object,
    votes: PropTypes.object,
}

export default withAppContext(withSessionContext(withToastMessageContext(withVotesContext(AppContainer))));
