import React from "react";
import {updatePlayersToDb, updateTimerToDb, updateToastMessageToDb} from "../../../dataStore/dataStore";
import {withAppContext} from '../../../context/appContext'
import capitalize from "lodash/capitalize"

const updatePlayerNameSelected = (players, playerName, hasGameStarted) => (playerIdx) => {

  const player = players[playerIdx]
  if (
    player === undefined ||
    hasGameStarted ||
    (playerName !== player.name && playerName !== "" )
  ) {
    return
  }

  player.isSelected = true

  updatePlayersToDb(players).catch((err) => {
    console.error(err)
  })
}

const toggleTimerAndSetPlayerTurn = (players, hasGameStarted) => (playerIdx) => {
  let currPlayer = {}
  const updatedPlayersState = players.slice().map((player, idx) => {
    player.isPlayerTurn = false

    if (idx === playerIdx) {
      player.isPlayerTurn = true
      currPlayer = player
    }

    return player
  })

  return updateTimerToDb({
      countdownValue: 180,
      hasTimerStarted: hasGameStarted
    })
        .then(() => updatePlayersToDb(updatedPlayersState))
        .then(() => updateToastMessageToDb(
            `${capitalize(currPlayer.name)}`,
            `You have 90 seconds to contribute to the hunt. Make them count!`,
            true
        ))
}

const PlayerNameCol = ({playerIdx, isNarrator, hasGameStarted, player, playerName, players}) => {
  let displayPlayerName = capitalize(player.name)

  if (isNarrator && hasGameStarted) {
    return (
        <td
            onClick={() => toggleTimerAndSetPlayerTurn(players, hasGameStarted)(playerIdx)}
            className={'player-stats-player-name-col'}
        >
          {displayPlayerName}
        </td>
    )
  }

  if (player.name === playerName ) {
    updatePlayerNameSelected(players, playerName, hasGameStarted)(playerIdx)
  }

  return (
    <td className={'player-stats-player-name-col'}>
      {displayPlayerName}
    </td>
  )
}

export default withAppContext(PlayerNameCol)
