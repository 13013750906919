import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RandomizeCharacterBtn from "./RandomizeCharacterBtn";
import {FaUserPlus} from 'react-icons/fa'
import {AppContext} from "../context/appContext";
import Tooltip from '../helper/tooltip/Tooltip'
import {updateGameStatusToDb, updatePastVotesToDb} from "../dataStore/dataStore";

class AdminButtons extends Component {
  static contextType = AppContext

  toggleGameState = () => {
    return updateGameStatusToDb({hasGameStarted: !this.context.hasGameStarted})
      .then(() => updatePastVotesToDb([]))
  }

  renderAdminButtons = () => {
    const startStopGameButton = (
      <Button
        key={'startStopButton'}
        variant="success"
        onClick={this.toggleGameState}
        className={'admin-buttons start-stop-button'}
      >
        {this.context.hasGameStarted ? "Stop game" : "Start game"}
      </Button>
    )
    const addPlayerButton = (
      <Tooltip
        id={"admin-button-add-user-button"}
        text={"Add a new player"}
        key={'addPlayerButton'}
      >
        <Button
          variant="outline-secondary"
          onClick={this.props.toggleAddPlayerModal}
          className={'admin-buttons add-player-button'}
        >
          <FaUserPlus/>
        </Button>
      </Tooltip>
    )
    const randomizeCharacterButton = (
      <RandomizeCharacterBtn
        key={'randomise-character-button'}
        players={this.context.players}
        className={'admin-buttons randomize-character-button'}
      />
    )


    const buttons = []
    
    if (this.context.hasGameStarted || this.context.isNarrator) {
      buttons.push(startStopGameButton)
    }

    if (this.context.isNarrator) {
      if (!this.context.hasGameStarted) {
        buttons.push(addPlayerButton)
        buttons.push(randomizeCharacterButton)
      }
    }
    return buttons
  }

  render() {
    return (
      <Row>
        <Col>
          {this.renderAdminButtons()}
        </Col>
      </Row>
    );
  }
}

export default AdminButtons;
