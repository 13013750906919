import React, {Component} from 'react';
import Toast from "react-bootstrap/Toast";
import {ToastMessageContext} from "../../context/toastMessageContext";
import {updateToastMessageToDb} from "../../dataStore/dataStore";

class ToastMessage extends Component {
  static contextType = ToastMessageContext

  closeToastMessage = () => {
    this.context.setIsToastMessageShown(false)
    return updateToastMessageToDb('', '', false)
  }

  render() {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'relative',
          zIndex: 99
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 10,
            right: 40,
          }}
        >
          <Toast
            delay={3000}
            onClose={this.closeToastMessage}
            show={this.context.isToastMessageShown}
            autohide
          >
            <Toast.Header
              className={this.context.headerMessage === 'Alert' ? "red-color" : "green-color"}
            >
              <strong className="mr-auto">{this.context.headerMessage}</strong>
            </Toast.Header>
            <Toast.Body>{this.context.message}</Toast.Body>
          </Toast>
        </div>
      </div>
    );
  }
}

export default ToastMessage;
