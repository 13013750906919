import React, { useState, createContext } from "react";

// Create Context Object
export const ToastMessageContext = createContext({
  message: 'i am a message',
  setMessage: () => {},
  isToastMessageShown: false,
  setIsToastMessageShown: () => {}
});

// Create a provider for components to consume and subscribe to changes
export const ToastMessageProvider = props => {
  const [message, setMessage] = useState('i am a message');
  const [headerMessage, setHeaderMessage] = useState('i am a header');
  const [isToastMessageShown, setIsToastMessageShown] = useState(false);

  return (
    <ToastMessageContext.Provider value={{
      message,
      setMessage,
      headerMessage,
      setHeaderMessage,
      isToastMessageShown,
      setIsToastMessageShown
    }}>
      {props.children}
    </ToastMessageContext.Provider>
  );
};

export function withToastMessageContext(Component) {
  return (props) => (
    <ToastMessageContext.Consumer>
      {(context) => (
        <Component
          {...context}
          {...props}
        />
      )}

    </ToastMessageContext.Consumer>
  )
}
