import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";

export default ({id, text, children}) => {
  return (
    <OverlayTrigger
      key={`tooltip-${id}`}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}
