import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import {withAppContext} from "../../context/appContext";
import {withVotesContext} from "../../context/votesContext";
import {getPivotVoteResult} from "../playerStats/PlayerTableCol/PlayerVoteCol"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import getTrClassName from "../playerStats/getTrClassName";
import Badge from "react-bootstrap/Badge";

class PastVotes extends Component {

  getPlayerPastVoteResults = (pastVote, playerIdx) => {
    const pivotVoteResult = getPivotVoteResult(pastVote, this.props.players)
    return pivotVoteResult[playerIdx] ? pivotVoteResult[playerIdx].join(', ') : ''
  }

  getPlayerNameByPlayerIdxs = (playerIdxs) => {
    if (playerIdxs === undefined) {
      return []
    }

    return playerIdxs.map((playerIdx) => {
      return this.props.players[playerIdx] ? this.props.players[playerIdx].name : ''
    })
  }

  renderPlayerNameCol = (player) => {
    if (player.name === this.props.playerName) {
      return (
        <td>
          <Badge variant="light" style={{"fontSize": "15px"}}>{player.name}</Badge>
        </td>
      )
    }
    return (
      <td>
        {player.name}
      </td>
    )
  }

  renderRows = () => {
    const playerRows = this.props.players.map((player, playerIdx) => {
      return (
        <tr
          className={getTrClassName({
            player,
            playerName: this.props.playerName,
            hasGameStarted: this.props.hasGameStarted
          })}
          key={`past-votes-table-row-${playerIdx}`}
        >
          <td className={"text-center"}>{playerIdx + 1}</td>
          {this.renderPlayerNameCol(player)}
          {this.props.pastVotes.map((pastVote, idx) => {
            return (
              <td key={`past-votes-table-col-${playerIdx}-${idx}`}>
                {this.getPlayerPastVoteResults(pastVote, playerIdx)}
              </td>
            )
          })}
        </tr>
      )
    })
    const noVotesPlayerIdx = this.props.players.length + 1
    playerRows.push((
      <tr
        key={`past-votes-table-row-no-votes-${noVotesPlayerIdx}`}
        className={"light-orange-background-color"}
      >
        <td className={"text-center"}>{noVotesPlayerIdx}</td>
        <td>No votes</td>
        {this.props.pastVotes.map((pastVote, idx) => {
          return (
            <td key={`past-votes-no-votes-col-${idx}`}>
              {this.getPlayerNameByPlayerIdxs(pastVote["noVotes"]).join(", ")}
            </td>
          )
        })}
      </tr>
    ))
    return playerRows
  }

  renderHeaderRow = () => {
    const noOfPastVotesRecorded = this.props.pastVotes.map((pastVote, idx) => {
      return (
        <th key={`past-votes-idx-header-row-${idx}`}>{idx + 1}</th>
      )
    })
    return (
      <tr>
        <th className={"text-center"}>#</th>
        <th style={{width: "100px"}}>Player Name</th>
        {noOfPastVotesRecorded}
      </tr>
    )
  }

  render() {
    if (this.props.pastVotes.length === 0) {
      return (
        <div>No past votes recorded</div>
      )
    }
    return (
      <div className={'past-votes'}>
        <Row>
          <Col>
            <div className={'past-votes-table'}>

              <Table bordered hover>
                <thead>
                {this.renderHeaderRow()}
                </thead>
                <tbody>
                {this.renderRows()}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>

    );
  }
}

export default withAppContext(withVotesContext(PastVotes));
