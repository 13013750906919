import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Table from "react-bootstrap/Table";
import {db, updateTimerToDb} from '../../dataStore/dataStore'
import TimerProgressBar from "./TimerProgressBar";
import {withAppContext} from "../../context/appContext";
import {withToastMessageContext} from "../../context/toastMessageContext";
import HeaderRow from "./HeaderRow";
import PlayerNameCol from "./PlayerTableCol/PlayerNameCol";
import PlayerStatusCol from "./PlayerTableCol/PlayerStatusCol";
import PlayerRoleCol from "./PlayerTableCol/PlayerRoleCol";
import PlayerDeleteCol from "./PlayerTableCol/PlayerDeleteCol";
import PlayerVoteCol from "./PlayerTableCol/PlayerVoteCol";
import getTrClassName from "./getTrClassName"
import {API, graphqlOperation} from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";
import get from "lodash/get";

class PlayerStats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countdownValue: 180,
      hasTimerStarted: false,
    }
  }

  componentDidMount() {
    // API.graphql(graphqlOperation(subscriptions.onUpdateTimer)).subscribe({
    //   next: (timers) => {
    //     const countdownValue = get(timers, "value.data.onUpdateTimer.countdownValue")
    //     const hasTimerStarted = get(timers, "value.data.onUpdateTimer.hasTimerStarted")
    //     this.setState({
    //       countdownValue,
    //       hasTimerStarted
    //     });
    //   },
    //   error: (err) => {console.log(err)}
    // })

    db.ref("timer").on("value", snapshots => {
      const snapshot = snapshots.val()
      Object.keys(snapshot).forEach((key) => {
        this.setState({[key]: snapshot[key]});
      })
    })
  }

  generatePlayerStats = () => {
    if (this.props.players.length === undefined) {
      return
    }

    const Cols = [
      PlayerNameCol, PlayerRoleCol, PlayerStatusCol,
      this.props.isNarrator && !this.props.hasGameStarted ? PlayerDeleteCol : PlayerVoteCol
    ]

    return this.props.players.map((player, playerIdx) => {
      return (
        <tr
          key={`${playerIdx}.${player.name}`}
          className={getTrClassName({
            player,
            hasGameStarted: this.props.hasGameStarted,
            playerName: this.props.playerName
          })}
        >
          <td className={"text-center"}>{playerIdx + 1}</td>
          {Cols.map((Col, idx) => (
            <Col
              key={`player-stats-col-${idx}`}
              playerIdx={playerIdx}
              player={player}
            />
          ))}
        </tr>
      )
    })
  }

  updateTimerState = (key, value) => {
    if (key === 'countdownValue' || key === 'hasTimerStarted') {
      return updateTimerToDb({
        countdownValue: this.state.countdownValue,
        hasTimerStarted: this.state.hasTimerStarted,
        [key]: value
      })
    }
  }

  render() {
    return (
      <div>
        <TimerProgressBar
          updateTimerState={this.updateTimerState}
          countdownValue={this.state.countdownValue}
          defaultValueInSeconds={180}
          hasTimerStarted={this.state.hasTimerStarted}
          isNarrator={this.props.isNarrator}
          hasGameStarted={this.props.hasGameStarted}
        />
        <Table bordered hover className={"player-stats"}>
          <HeaderRow />
          <tbody>
          {this.generatePlayerStats()}
          </tbody>
        </Table>
      </div>
    );
  }
}

PlayerStats.propsType = {
  handleAliveState: PropTypes.func,
  getRandomPlayer: PropTypes.func,
  isVoteShown: PropTypes.bool,
  votes: PropTypes.object,
  setMessage: PropTypes.func,
  setIsToastMessageShown: PropTypes.func,
  playerName: PropTypes.string
}

export default withAppContext(withToastMessageContext(PlayerStats))
