import React, {Component} from 'react';
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import {AuthState} from '@aws-amplify/ui-components'
import {withSessionContext} from "../../context/sessionContext";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";


class LoginModal extends Component {
    handleClose = () => {
        this.props.setIsLoginModalShown(false)
    }

    handleAuthStateChange = (nextAuthState) => {
        if (nextAuthState === AuthState.SignedIn) {
            this.handleClose()
        }
    }

    render() {
        return (
            <Modal show={this.props.isLoginModalShown} onHide={this.handleClose}>
                <Modal.Body className="login-modal">
                    <AmplifyAuthenticator federated={{}}>
                        <AmplifySignUp
                            slot="sign-up"
                            usernameAlias="email"
                            formFields={[
                                {
                                    type: "name",
                                    label: "First name",
                                    placeholder: "sebastian",
                                    required: true,
                                },
                                {
                                    type: "email",
                                    label: "Email",
                                    placeholder: "sebastian987@gmail.com",
                                    required: true,
                                },
                                {
                                    type: "password",
                                    label: "Password",
                                    placeholder: "********",
                                    required: true,
                                }
                            ]}
                        />
                        <AmplifySignIn
                            headerText="Join the hunt"
                            slot="sign-in"
                            federated={{}}
                            formFields={[
                                {
                                    type: "email",
                                    label: "Email",
                                    placeholder: "sebastian987@gmail.com",
                                    required: true,
                                },
                                {
                                    type: "password",
                                    label: "Password",
                                    placeholder: "********",
                                    required: true,
                                }
                            ]}
                        />
                    </AmplifyAuthenticator>
                </Modal.Body>
            </Modal>
        );
    }
}

LoginModal.propsType = {
    isLoginModalShown: PropTypes.bool,
    setIsLoginModalShown: PropTypes.func,
    setUserData: PropTypes.func,
    userData: PropTypes.object
}

export default withSessionContext(LoginModal);