import React, { useState, createContext } from "react";

// Create Context Object
export const VotesContext = createContext({
  votes: {},
  setVotes: () => {},
  isVoteShown: false,
  setIsVoteShown: () => {}
});

// Create a provider for components to consume and subscribe to changes
export const VotesProvider = props => {
  const [votes, setVotes] = useState({});
  const [isVoteShown, setIsVoteShown] = useState(true);
  const [pastVotes, setPastVotes] = useState([]);

  return (
    <VotesContext.Provider value={{
      votes,
      setVotes,
      isVoteShown,
      setIsVoteShown,
      pastVotes,
      setPastVotes
    }}>
      {props.children}
    </VotesContext.Provider>
  );
};

export function withVotesContext(Component) {
  return (props) => (
    <VotesContext.Consumer>
      {(context) => (
        <Component
          {...context}
          {...props}
        />
      )}

    </VotesContext.Consumer>
  )
}
