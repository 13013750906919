import {updatePlayersToDb} from "../../dataStore/dataStore";


export default (players) => {
  const playersAlive = players.reduce((acc, player, idx) => {
    if (player.isAlive) {
      acc.push(idx)
    }
    return acc
  }, [])
  const randomPlayerIdx = playersAlive[Math.floor(Math.random() * playersAlive.length)]

  for (let i = 0; i< players.length; i ++) {
    players[i].isPlayerTurn = (i === randomPlayerIdx);
  }
  return updatePlayersToDb(players)
}
