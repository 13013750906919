import React from "react";
import Form from "react-bootstrap/Form";
import {withAppContext} from "../../context/appContext";
import {updatePlayersToDb} from "../../dataStore/dataStore";
import capitalize from "lodash/capitalize"

const PlayerName = ({hasGameStarted, playerName, setPlayerName, players}) => {

  // const isPlayerSelected = () => {
  //   const filterPlayers = players.filter((player) => {
  //     return player.name === playerName && player.isSelected
  //   })
  //
  //   return filterPlayers.length !== 0
  // }

  const handleOnChange = ({target}) => {
    const playerName = target.value

    players.forEach((player) => {
      if (player.name === playerName){
        player.isSelected = true
      }
    })

    return updatePlayersToDb(players)
      .then(() => setPlayerName(playerName))
  }

  return (
    <Form className={"player-name"}>
      <Form.Group controlId="formPlayerName">
        <Form.Control
          // disabled={hasGameStarted || isPlayerSelected()}
          disabled={true}
          type="text"
          placeholder="Sebastian"
          value={capitalize(playerName)}
          onChange={handleOnChange}
        />
      </Form.Group>
    </Form>
  )
}

export default withAppContext(PlayerName)
