import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import roles from './roles.json'
import addPlayer, {isPlayerNameValid} from "../players/addPlayer";
import {withAppContext} from "../../context/appContext";
import getPlayers from '../players/getPlayers'
import PropTypes from "prop-types";
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'

class AddPlayerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newPlayerName: '',
            newPlayerRole: '',
            isPlayerNameValid: true,
            playerNames: []
        };
    }

    componentDidMount() {
        if (this.props.isNarrator) {
            this.updateEligiblePlayerNames()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.newPlayerName !== prevProps.newPlayerName) {
            this.setState({
                newPlayerName: this.props.newPlayerName,
                newPlayerRole: roles[0]
            });
        }

        if (
            (
                this.props.players !== prevProps.players
                || this.state.playerNames.length === 0
            )
            && this.props.isNarrator
        ) {
            this.updateEligiblePlayerNames()
        }
    }

    updateEligiblePlayerNames = () => {
        getPlayers().then((playerNames) => {
            const currentPlayerNames = this.props.players.map((player) => {
                return lowerCase(get(player, 'name'))
            })

            const filteredPlayerNames = playerNames.filter((playerName) => {
                return !currentPlayerNames.includes(playerName)
            })

            this.setState({
                playerNames: filteredPlayerNames,
                newPlayerName: filteredPlayerNames[0],
                newPlayerRole: roles[0],
                isPlayerNameValid: filteredPlayerNames.length > 0
            });
        })
    }

    handleAddPlayer = (e) => {
        if (!isPlayerNameValid(this.props.players, this.state.newPlayerName)) {
            this.setState({isPlayerNameValid: false});
            return
        }

        e.stopPropagation()

        return addPlayer(
            this.state.newPlayerName,
            this.state.newPlayerRole,
            this.props.players,
            this.props.toggleAddPlayerModal
        )
    }

    updateNewPlayer = (formType) => {
        return ({target}) => {
            const newPlayerName = lowerCase(target.value)

            this.setState({
                [formType]: newPlayerName,
                isPlayerNameValid: isPlayerNameValid(this.props.players, newPlayerName)
            });
        }
    }

    renderRoleOptions = () => {
        return roles.map((role) => {
            return (
                <option key={role}>{role}</option>
            )
        })
    }

    renderPlayerNamesOptions = () => {
        return this.state.playerNames.map((playerName) => {
            return (
                <option key={playerName}>{capitalize(playerName)}</option>
            )
        })
    }

    render() {
        return (
            <Modal show={this.props.isAddPlayerModalShown} onHide={this.props.toggleAddPlayerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new player</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewPlayerName" onChange={this.updateNewPlayer("newPlayerName")}>
                            <Form.Label>New Player Name</Form.Label>
                            <Form.Control as="select">
                                {this.renderPlayerNamesOptions()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Player name must be unique</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formRole" onChange={this.updateNewPlayer("newPlayerRole")}>
                            <Form.Label>New Player Role</Form.Label>
                            <Form.Control as="select">
                                {this.renderRoleOptions()}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.toggleAddPlayerModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.handleAddPlayer}
                        disabled={!this.state.isPlayerNameValid}
                    >
                        Add player
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

AddPlayerModal.propType = {
    players: PropTypes.array,
    isNarrator: PropTypes.bool
}

export default withAppContext(AddPlayerModal);
