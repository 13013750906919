import { API } from 'aws-amplify';
import get from 'lodash/get'
import filter from 'lodash/filter'

const getPlayerNames = (users) => {
    return users.map((user) => {
        return get(filter(get(user, 'Attributes'), (attribute) => attribute.Name === 'name'), '0.Value')
    })
}

export default () => {
    return API
        .get("usersApi", "/users", {})
        .then(({users}) => {
            return getPlayerNames(users)
        })
        .catch(err => {
            console.error(err)
            return []
        });
}