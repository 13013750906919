import {withAppContext} from "../../../context/appContext";
import {FaHeart, FaRegHeart} from "react-icons/fa";
import React from "react";
import {updatePlayersToDb, updateToastMessageToDb} from "../../../dataStore/dataStore";


const getToastMessage = (isAlive, playerName) => {
  if (isAlive) {
    return `God must have answered our prayer because ${playerName} is revived`
  }

  return `${playerName} has fallen`
}

const handleAliveState = (players, isNarrator, playerIdx) => {
  if (players[playerIdx] === undefined || !isNarrator) {
    return
  }

  const player = players[playerIdx] || {}
  players[playerIdx].isAlive = !players[playerIdx].isAlive
  return updatePlayersToDb(players).then(
    () => updateToastMessageToDb(
      'Alert',
      getToastMessage(player.isAlive, player.name),
      true
    )
  )


}

const PlayerStatusCol = ({player, playerIdx, players, isNarrator}) => {
  if (player.isAlive) {
    return (
      <td
        onClick={() => handleAliveState(players, isNarrator, playerIdx)}
        style={{"textAlign": "center"}}
        className={"player-stats-is-alive-col"}
      >
        {<FaHeart className={"player-stats-is-alive-col red-color"}/>}
      </td>
    )
  }

  return (
    <td
      onClick={() => handleAliveState(players, isNarrator, playerIdx)}
      style={{"textAlign": "center"}}
    >
      {<FaRegHeart className="player-stats-is-alive-col grey-color"/>}
    </td>
  )
}

export default withAppContext(PlayerStatusCol)
