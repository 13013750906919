import {FaTrash} from "react-icons/fa";
import React from "react";
import deletePlayer from "../../players/deletePlayer";
import {withAppContext} from "../../../context/appContext";


const handleDeletePlayer = (players, playerIdx, isNarrator) => {
  if (isNarrator !== true) {
    return
  }

  return deletePlayer(playerIdx, players)
}

const PlayerDeleteCol = ({players, playerIdx, isNarrator}) => {

  return (
    <td
      className={"text-center player-stats-delete-col"}
      onClick={() => handleDeletePlayer(players, playerIdx, isNarrator)}
    >
      <FaTrash/>
    </td>
  )
}

export default withAppContext(PlayerDeleteCol)
