import React from "react";
import {withAppContext} from '../../../context/appContext'

const PlayerRoleCol = ({player, playerName, isNarrator, hasGameStarted}) => {
  let roleName = ''
  const isAbleToShowRoleName = (
    isNarrator ||
    (player.name.toLowerCase() === playerName.toLowerCase() && hasGameStarted)
  )

  if (isAbleToShowRoleName) {
    roleName = player.role
  }

  return (
    <td className={"text-center"}>
      {roleName}
    </td>
  )
}

export default withAppContext(PlayerRoleCol)
