import {updatePlayersToDb} from "../dataStore/dataStore";
import {FaRandom} from 'react-icons/fa'
import Button from "react-bootstrap/Button";
import React from "react";
import Tooltip from "../helper/tooltip/Tooltip";

export default ({players, className}) => {

  const randomizeCharacter = (players) => {
    const characters = players.map((player) => player.role)

    for (let i = 0; i < characters.length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const temp = characters[randomIndex]
      characters[randomIndex] = characters[i]
      characters[i] = temp
    }

    for (let i = 0; i < players.length; i++) {
      players[i].role = characters[i]
    }

    return updatePlayersToDb(players)
  }

  return (
    <Tooltip
      id={"admin-button-randomize-role-button"}
      text={"Randomise character roles"}
      key={'randomizeCharacterButton'}
    >
      <Button
        variant="outline-secondary"
        onClick={() => randomizeCharacter(players)}
        className={className}
      >
        <FaRandom/>
      </Button>
    </Tooltip>
  )
}
