import React, {Component} from 'react';

import AppContainer from "./appContainer/AppContainer";
import AppHeader from "./appHeader/AppHeader";
import LoginModal from "./authentication/loginModal/LoginModal";
import ToastMessage from "./helper/toastMessage/ToastMessage";
import {AppProvider} from './context/appContext'
import {ToastMessageProvider} from "./context/toastMessageContext";
import {VotesProvider} from "./context/votesContext";
import {SessionProvider} from "./context/sessionContext";
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';

Amplify.configure(awsconfig);

class App extends Component {

    render() {
        return (
            <AppProvider>
                <SessionProvider>
                    <VotesProvider>
                        <ToastMessageProvider>
                            <AppHeader/>
                            <ToastMessage/>
                            <AppContainer/>
                            <LoginModal/>
                        </ToastMessageProvider>
                    </VotesProvider>
                </SessionProvider>
            </AppProvider>
        );
    }

}

export default App;
