

export default ({player, hasGameStarted, playerName, cssPrefix}) => {
  let className = {}

  if (player.isPlayerTurn && hasGameStarted) {
    className = {"table-tr-is-player-turn": true}
  }

  if (player.isSelected) {
    className = {...className, "table-tr-is-selected": true}
  }

  if (player.isSelected && player.name === playerName) {
    className = {...className, "table-tr-is-selected-and-is-current-player": true}
  }

  if (!player.isAlive) {
    className = {...className, "table-tr-is-not-alive": true}
  }
  return Object.keys(className).join(' ')
}
