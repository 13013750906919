import React, {useState} from "react";
import {updateAdminUuidToDb} from "../dataStore/dataStore";
import getPlayer from "../appContainer/players/getPlayer";
import {setCookie} from "../dataStore/cookie";
import get from 'lodash/get';

export const AppContext = React.createContext({
    playerName: '',
    setPlayerName: () => {
    },
    players: [],
    setPlayers: () => {
    },
    isNarrator: false,
    setIsNarrator: () => {
    },
    hasGameStarted: false,
    setHasGameStarted: () => {
    }
})

export const isNarrator = (userData) => {
    if (get(userData, 'signInUserSession.accessToken.payload.cognito:groups.0') === 'admin') {
        return true
    }
    return false
}

export const AppProvider = props => {
    const [playerName, setPlayerNameState] = useState('');
    const [players, setPlayers] = useState([]);
    const [isNarrator, setIsNarrator] = useState(false);
    const [hasGameStarted, setHasGameStarted] = useState(false);

    const setPlayerName = (playerName) => {
        if (playerName === undefined) {
            return
        }
        const isNarrator = playerName.toLowerCase() === 'narrator'
        setCookie('werewolves-playerName', playerName)
        setPlayerNameState(playerName)
        setIsNarrator(isNarrator)

        if (isNarrator) {
            return updateAdminUuidToDb(getPlayer().uuid)
        }
    }

    return (
        <AppContext.Provider value={{
            playerName, setPlayerName,
            players, setPlayers,
            isNarrator, setIsNarrator,
            hasGameStarted, setHasGameStarted,
        }}>
            {props.children}
        </AppContext.Provider>
    );
};

export function withAppContext(Component) {
    return (props) => (
        <AppContext.Consumer>
            {(appContext) => (
                <Component
                    {...appContext}
                    {...props}
                />
            )}

        </AppContext.Consumer>
    )
}
