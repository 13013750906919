import React from "react";
import {withVotesContext} from "../../../context/votesContext";
import {withAppContext} from "../../../context/appContext";
import {updateVoteToDb} from "../../../dataStore/dataStore";
import Form from "react-bootstrap/Form";

const getPlayerIdx = (players, playerName) => {
  for (let i = 0; i < players.length; i++) {
    if (players[i].name === playerName) {
      return i
    }
  }
}

const handleVoteChecked = (currPlayerIdx, playerIdx, votes) => {
    return updateVoteToDb({
      ...votes,
      [currPlayerIdx]: votes[currPlayerIdx] === playerIdx ? -1 : playerIdx
    })
}

export const getPivotVoteResult = (votes, players) => {
  return Object.keys(votes).reduce((acc, key) => {
    const voteIdx = votes[key]

    if (voteIdx === -1 || voteIdx === undefined) {
      return acc
    }

    if (acc[voteIdx] === undefined) {
      acc[voteIdx] = []
    }

    if (players[key] !== undefined) {
      acc[voteIdx].push(players[key].name)
    }

    return acc
  }, {})
}

const PlayerVoteCol = ({votes, isVoteShown, players, playerName, playerIdx, player}) => {
  if (isVoteShown) {
    const pivotVoteResult = getPivotVoteResult(votes, players)
    return (
      <td className={"text-center"}>
        {pivotVoteResult[playerIdx] ? pivotVoteResult[playerIdx].join(', ') : 'No votes'}
      </td>
    )
  }

  const currPlayerIdx = getPlayerIdx(players, playerName)
  let votedPlayerIdx = votes[currPlayerIdx] !== undefined ? votes[currPlayerIdx] : -1

  const isDisabled = (
    (votedPlayerIdx !== -1 && votedPlayerIdx !== playerIdx)
    || currPlayerIdx === playerIdx
    || currPlayerIdx === undefined
    || !players[currPlayerIdx].isAlive
    || !player.isAlive
  )

  return (
    <td className={"text-center"}>
      <Form.Check
        disabled={isDisabled}
        onChange={() => handleVoteChecked(currPlayerIdx, playerIdx, votes)}
        checked={votedPlayerIdx === playerIdx }
        type={"checkbox"}
        id={`player-stats-player-vote-col-${playerIdx}`}
      />
    </td>
  )
}

export default withAppContext(withVotesContext(PlayerVoteCol))
